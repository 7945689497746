import CssBaseline from '@material-ui/core/CssBaseline';
import Facebook from 'mdi-material-ui/Facebook';
import Grid from '@material-ui/core/Grid';
import Instagram from 'mdi-material-ui/Instagram';
import Itunes from 'mdi-material-ui/Itunes';
import Music from 'mdi-material-ui/Music';
import React from 'react';
import ShoppingMusic from 'mdi-material-ui/ShoppingMusic';
import Soundcloud from 'mdi-material-ui/Soundcloud';
import Spotify from 'mdi-material-ui/Spotify';
import Typography from '@material-ui/core/Typography';
import Youtube from 'mdi-material-ui/Youtube';
import { withStyles } from '@material-ui/core/styles';
import * as assets from './assets';

class App extends React.PureComponent {
    constructor(props) {
        super(props);

        this.icons = [
            { Icon: Facebook, href: 'https://www.facebook.com/beatmount/' },
            { Icon: Instagram, href: 'https://www.instagram.com/beatmount/' },
            { Icon: Youtube, href: 'https://www.youtube.com/channel/UCDX5pC6YH7QGxeTsbXhdFug' },
            { Icon: Soundcloud, href: 'https://soundcloud.com/beatmount' },
            { Icon: Spotify, href: 'https://open.spotify.com/artist/78dUeeMpavPUMBv0kGCape' },
            { Icon: Itunes, href: 'https://itunes.apple.com/us/artist/beatmount/1296468521' },
            { Icon: ShoppingMusic, href: 'https://www.beatport.com/artist/beatmount/731928' },
            { Icon: Music, href: 'https://spinninrecords.com/profile/beatmount' },
        ];
    }

    renderIcons() {
        return (
            <Grid className={this.props.classes.iconContainer} container spacing={8}>
                {this.icons.map(({ Icon, href }, index) => (
                    <Grid key={index} className={this.props.classes.iconContainerItem} item xs={6} md={3}>
                        <a className={this.props.classes.iconContainerItemLink} target="_blank" rel="noopener noreferrer" href={href}>
                            <Icon className={this.props.classes.icon} />
                        </a>
                    </Grid>
                ))}
            </Grid>
        );
    }

    renderFooter() {
        return (
            <footer className={this.props.classes.footer}>
                <div className={this.props.classes.footerContent}>
                    <Typography color={'inherit'} variant={'body1'}>
                        &copy; {`${new Date().getFullYear()} Beatmount. All rights reserved. Unauthorized use prohibited.`}
                    </Typography>
                </div>
            </footer>
        );
    }

    render() {
        return (
            <React.Fragment>
                <CssBaseline />
                <div className={this.props.classes.app}>
                    <div className={this.props.classes.overlay} />
                    <div className={this.props.classes.container}>
                        <div className={this.props.classes.logo}>
                            <img src={assets.logo} alt={'Beatmount Logo'} />
                        </div>
                        {this.renderIcons()}
                    </div>
                    {this.renderFooter()}
                </div>
            </React.Fragment>
        );
    }
}

export default withStyles(theme => ({
    app: {
        background: `url(${assets.background}) no-repeat 50%`,
        backgroundColor: '#282c34',
        backgroundSize: 'cover',
        display: 'flex',
        minHeight: '100vh',
        paddingBottom: 50,
        position: 'relative'
    },
    container: {
        margin: 'auto',
        zIndex: 2
    },
    footer: {
        bottom: 0,
        display: 'flex',
        height: 50,
        position: 'absolute',
        width: '100%',
        textAlign: 'center',
        zIndex: 2
    },
    footerContent: {
        color: '#eceff1',
        margin: 'auto',
        padding: `0 ${theme.spacing.unit * 2}px`
    },
    icon: {
        fill: '#eceff1',
    },
    iconContainer: {
        padding: 24
    },
    iconContainerItem: {
        display: 'flex',
        height: 64,
        [theme.breakpoints.only('xs')]: {
            height: 48
        }
    },
    iconContainerItemLink: {
        margin: 'auto',
        '&:hover $icon': {
            fill: '#babdbe'
        },
    },
    logo: {
        display: 'block',
        fill: '#eceff1',
        margin: '24px auto',
        width: 254,
        [theme.breakpoints.only('xs')]: {
            width: 174
        }
    },
    overlay: {
        backgroundImage: 'radial-gradient(circle farthest-side at center, rgba(255,255,255, 0), rgba(33, 33, 33, 1) 75%)',
        height: '100%',
        opacity: 1,
        position: 'absolute',
        width: '100%',
        zIndex: 1
    }
}))(App);
